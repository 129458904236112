import React, { useEffect } from 'react';
import './style.css'

export const ModalWindow  = () =>{
    const linkClick = (id) =>{
        document.querySelector(".contacts").classList.add("active");
        document.body.classList.add("modal-open")
        if (document.getElementById(id)) {
            document.querySelectorAll('.contacts__modal').forEach(item =>{
                item.classList.remove("active")
            })
        }
        
        document.getElementById(id).classList.add("active")
    }
    const switchOff = (event , type = null) =>{
        if (event.target === event.currentTarget) {
            if (event.currentTarget.classList.contains("active")) {
                document.querySelector(".contacts").classList.remove("active");
                document.body.classList.remove("modal-open")
            }
        }
        if (type === "span") {
            document.querySelector(".contacts").classList.remove("active");
            document.body.classList.remove("modal-open")
        }
    }

    useEffect(() =>{
        
    })

    return (

        <>
            <div className="contacts" onClick={(e) => switchOff(e)}>
                <div className="contacts__modal-wrapper">
                    <div className="contacts__modal" id="about">
                        <div className="contacts__modal-inner">
                            <div className="contacts__modal-top">
                                
                                <h2 className="">
                                    GOLDSWORTH CAPITAL
                                </h2>
                                <span className="contacts__modal-cross-span" onClick={(e) => switchOff(e, "span")}>
                                    <svg height="10" width="10"   version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                            viewBox="0 0 512 512" style={{enableBackground:'new 0 0 512 512'}} xmlSpace="preserve">
                                        <g>
                                            <g>
                                                <polygon points="512,59.076 452.922,0 256,196.922 59.076,0 0,59.076 196.922,256 0,452.922 59.076,512 256,315.076 452.922,512 
                                                    512,452.922 315.076,256 		"/>
                                            </g>
                                        </g>
                                    </svg>
                                </span>
                            </div>
                            
                            <p>
                                Goldsworth Capital is a boutique investment fund focused on alternative investments. 
                                Our two main areas of focus are real estate in emerging economies and deep technology. 
                                The philosophy behind this dichotomy is to secure a stable yet profit-generating portfolio of real estate as a foundation today, 
                                while at the same time to discern early-stage startups building cutting-edge technologies that have a meaningful potential to transform the economies of tomorrow. 
                                We prefer to work actively and closely with relevant stakeholders, be they real estate managers or startup founders, 
                                giving strategic guidance to help them maximise the value of their endeavours.   
                                
                            </p>

                        </div>
                        
                    </div>
                    <div className="contacts__modal" id="contacts">
                        <div className="contacts__modal-inner">
                        <div className="contacts__modal-top">
                            
                            <h2 className="">
                                Contact Us
                            </h2>
                            <span className="contacts__modal-cross-span" onClick={(e) => switchOff(e, "span")}>
                                <svg height="10" width="10"   version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                        viewBox="0 0 512 512" style={{enableBackground:'new 0 0 512 512'}} xmlSpace="preserve">
                                    <g>
                                        <g>
                                            <polygon points="512,59.076 452.922,0 256,196.922 59.076,0 0,59.076 196.922,256 0,452.922 59.076,512 256,315.076 452.922,512 
                                                512,452.922 315.076,256 		"/>
                                        </g>
                                    </g>
                                </svg>
                            </span>
                        </div>
                        
                        <p>
                        We welcome any pitches and investment proposals falling into one of the two categories outlined on our landing page (real estate in emerging economies or early-stage startups). Our investment ticket is typically 100k-1m USD. Additionally, we are on the lookout for entry-level investment analysts or interns. Use contact details below to reach out us with your enquiry or proposal:
                        
                        
                        Goldsworth, PO Box 1446, Roadtown, VG 1110, Tortola, BVI
                        
                        
                              
                        </p>
                        <p>
                            <a href="mailto:info@goldsworth.org">info@goldsworth.org</a>
                        </p>
                        </div>
                    </div>
                    <div className="contacts__modal" id="analysis">
                        <div className="contacts__modal-inner">
                        <div className="contacts__modal-top">
                            
                            <h2 className="">
                                ATOM by COSMOS Analysis
                            </h2>
                            <span className="contacts__modal-cross-span" onClick={(e) => switchOff(e, "span")}>
                                <svg height="10" width="10"   version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                        viewBox="0 0 512 512" style={{enableBackground:'new 0 0 512 512'}} xmlSpace="preserve">
                                    <g>
                                        <g>
                                            <polygon points="512,59.076 452.922,0 256,196.922 59.076,0 0,59.076 196.922,256 0,452.922 59.076,512 256,315.076 452.922,512 
                                                512,452.922 315.076,256 		"/>
                                        </g>
                                    </g>
                                </svg>
                            </span>
                        </div>
                        
                        
                        <h4>
                            THIS IS NOT AN INVESTMENT ADVICE BUT RATHER A DISCUSSION OF THE PROJECT
                        </h4>
                        
                        <h5>
                            Atom by Cosmos:
                        </h5>
                        <p>
                        * Traded at $4.42 on 1/1/2020, peaked (so far) at $5.22 on 2/12/2020. Currently trading at approx. $4.05-4.10 range. Compared to most other altcoins among top-50, especially when excluding coins such as BCH, LTC, XRP, BSV, it is still in the negative territory compared to both the beginning of the year (-10% YTD) and the year's high (-24%) so far. It is thus far the only major technology-driven project that has not yet been discovered by the markets. Market capitalization (mcap) is approx. $750m so far.
                        </p>
                        <p>
                        * Being a protocol for cross-blockchain communications, we consider COSMOS to be comparable to ChainLink (LINK, mcap $2.35b, YTD +379%, x3.13 higher than COSMOS) and Cardano (ADA, mcap $3.21b, YTD +376%, x4.28 higher than COSMOS). Fundamentally, COSMOS offers a value proposition and technology, ecosystem and partnership strength comparable to these projects, compared to which it has a 3-4 times lower mcap.
                        </p>
                        <p>
                        * Importantly, COSMOS debuted on exchanges only in Q2 2019 and thus it has never experienced a bull-market, either Bitcoin-driven or alt-coin driven. Its All-Time-High was $7.22, which needs to be broken, should COSMOS reach valuations comparable to ChainLink (LINK) and Cardano (ADA). At the same time, the project itself started in 2015 and has gone through several rounds of considerable R&D stages.
                        </p>
                        <p>
                        * Furthermore, the technical roadmap for 2020 includes launch in production of the test-net as well as the main-net, in Q3 and Q4 2020 respectively, which makes it the last of the top-tier cryptocurrency projects that is yet to experience a proper launch of the main network.
                        </p>
                        
                        <p>
                        * There are notable projects that emerged in the Ethereum and Hyperledger ecosystems that chose to migrate to COSMOS. Furthermore, the biggest cryptocurrency exchanges in the world, such as Binance and OKex chose to build their own blockchains using COSMOS infrastructure. Many of Binance's incubated and accelerated cryptocurrencies likewise launched using COSMOS blockchain or its SDKs and infrastructure. The partnership ecosystem includes over 100+ confirmed partners.

                        </p>
                        <p>
                                                    * If blockchain technology market size (not to be confused with the total cryptocurrency mcap) amounts to approximately $120b, then we estimate that the first major inter-blockchain communication protocol in production should be worth at least 3% of that value, i.e. $3.6b or more.

                        </p>
                              
                        </div>
                    </div>
                </div>
                <ul className="contacts__button-wrap">
                    <li className="contacts__button" onClick={()=>linkClick('about')}>
                        <div className="contacts__span">
                            <a>About</a>
                            <span className="span__hidden">
                                About
                            </span>
                        </div>
                    </li>
                    <li className="contacts__button" onClick={()=>linkClick('contacts')}>
                        <div className="contacts__span">
                            <a >Contact</a>
                            <span className="span__hidden">
                                Contact
                            </span>
                        </div>
                         
                    </li>
                    <li className="contacts__button" onClick={()=>linkClick('analysis')}>
                        <div className="contacts__span">
                            <a >Analysis</a>
                            <span className="span__hidden">
                                Analysis
                            </span>
                        </div>
                        
                    </li>
                </ul>
            </div>
        </>
    )
}